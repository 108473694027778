import * as types from './types'

export default {
  [types.MUTATE_RISK_PROTECTION_SIDEBAR]: (state, payload) => {
    state.riskProtectionSidebar = { ...state.riskProtectionSidebar, ...payload }
  },
  [types.MUTATE_RISK_PROTECTION_PRODUCTS]: (state, payload) => {
    state.riskProtectionProducts = payload
  },
  [types.MUTATE_RISK_PROTECTION_COMPANIES]: (state, payload) => {
    state.riskProtectionCompanies = payload
  },
  [types.MUTATE_RISK_PROTECTION_COVERAGES]: (state, payload) => {
    state.riskProtectionCoverages = payload
  },
  [types.MUTATE_RISK_PROTECTION_NEEDS]: (state, payload) => {
    state.riskProtectionNeeds = payload
  },
  [types.MUTATE_RISKS_PROTECTIONS]: (state, payload) => {
    state.risksProtections = payload
  },
  [types.MUTATE_ASSET_REBALANCING_METODOLOGIES]: (state, payload) => {
    state.assetRebalancingMetodologies = payload
  },
  [types.MUTATE_WALLET_INVESTMENT_GOALS_SIDEBAR]: (state, payload) => {
    state.walletInvestmentGoalsSidebar = { ...state.walletInvestmentGoalsSidebar, ...payload }
  },
  [types.MUTATE_INVESTMENT_PROFILE_SIDEBAR]: (state, payload) => {
    state.investmentProfileSidebar = { ...state.investmentProfileSidebar, ...payload }
  },
  [types.MUTATE_IMPLEMENTATIONS_SIDEBAR]: (state, payload) => {
    state.implementationsSidebar = { ...state.implementationsSidebar, ...payload }
  },
  [types.MUTATE_IMPLEMENTATIONS]: (state, payload) => {
    state.implementations = payload
  },
  [types.MUTATE_PLANNING_STATUS]: (state, payload) => {
    state.planningStatus = payload
  },
  [types.MUTATE_OBJECTIVES]: (state, payload) => {
    state.objectives = payload
  }
}
