// Getters
export const CONSULTANTS_SEARCH_RESULT = 'consultant/CONSULTANTS_SEARCH_RESULT'
export const CONSULTANT_AGENT_TYPES = 'consultant/CONSULTANT_AGENT_TYPES'
export const PRODUCTS = 'consultant/PRODUCTS'
export const DETAILS_GENERAL_INFORMATION = 'consultant/DETAILS_GENERAL_INFORMATION'
export const DETAILS_COMPANY_DATA = 'consultant/DETAILS_COMPANY_DATA'
export const DETAILS_NETWORK_DATA = 'consultant/DETAILS_NETWORK_DATA'
export const DIRECT_TEAM = 'consultant/DIRECT_TEAM'
export const NEW_CONSULTANT_SIDEBAR = 'consultant/NEW_CONSULTANT_SIDEBAR'
export const TRANSFERRED_CONSULTANTS = 'consultant/TRANSFERRED_CONSULTANTS'
export const TRANSFERABLE_CONSULTANTS = 'consultant/TRANSFERABLE_CONSULTANTS'
export const CHANGE_CONSULTANT_ROLE_SIDEBAR = 'consultant/CHANGE_CONSULTANT_ROLE_SIDEBAR'
export const AFFILIATION_TYPES = 'consultant/AFFILIATION_TYPES'

// Mutations
export const MUTATE_CONSULTANTS_SEARCH_RESULT = 'consultant/MUTATE_CONSULTANTS_SEARCH_RESULT'
export const MUTATE_CONSULTANT_AGENT_TYPES = 'consultant/MUTATE_CONSULTANT_AGENT_TYPES'
export const MUTATE_PRODUCTS = 'consultant/MUTATE_PRODUCTS'
export const CLEAR_DETAILS_GENERAL_INFORMATION = 'consultant/CLEAR_DETAILS_GENERAL_INFORMATION'
export const MUTATE_DETAILS_GENERAL_INFORMATION = 'consultant/MUTATE_DETAILS_GENERAL_INFORMATION'
export const MUTATE_DETAILS_COMPANY_DATA = 'consultant/MUTATE_DETAILS_COMPANY_DATA'
export const MUTATE_DETAILS_NETWORK_DATA = 'consultant/MUTATE_DETAILS_NETWORK_DATA'
export const MUTATE_DIRECT_TEAM = 'consultant/MUTATE_DIRECT_TEAM'
export const MUTATE_NEW_CONSULTANT_SIDEBAR= 'consultant/MUTATE_NEW_CONSULTANT_SIDEBAR'
export const MUTATE_TRANSFERRED_CONSULTANTS = 'consultant/MUTATE_TRANSFERRED_CONSULTANTS'
export const MUTATE_TRANSFERABLE_CONSULTANTS = 'consultant/MUTATE_TRANSFERABLE_CONSULTANTS'
export const MUTATE_CHANGE_CONSULTANT_ROLE_SIDEBAR = 'consultant/MUTATE_CHANGE_CONSULTANT_ROLE_SIDEBAR'
export const MUTATE_AFFILIATION_TYPES = 'consultant/MUTATE_AFFILIATION_TYPES'

// Actions
export const SEARCH_CONSULTANTS = 'consultant/SEARCH_CONSULTANTS'
export const GET_CONSULTANT = 'consultant/GET_CONSULTANT'
export const SAVE_CONSULTANT = 'consultant/SAVE_CONSULTANT'
export const DELETE_CONSULTANT = 'consultant/DELETE_CONSULTANT'
export const GET_DETAILS_GENERAL_INFORMATION = 'consultant/GET_DETAILS_GENERAL_INFORMATION'
export const GET_DETAILS_COMPANY_DATA = 'consultant/GET_DETAILS_COMPANY_DATA'
export const GET_DETAILS_NETWORK_DATA = 'consultant/GET_DETAILS_NETWORK_DATA'
export const GET_DIRECT_TEAM = 'consultant/GET_DIRECT_TEAM'
export const OPEN_NEW_CONSULTANT_SIDEBAR = 'consultant/OPEN_NEW_CONSULTANT_SIDEBAR'
export const GET_CONSULTANT_INFO = 'consultant/GET_CONSULTANT_INFO'
export const GET_CEP = 'consultant/GET_CEP'
export const SAVE_CONSULTANT_BASIC_DATA = 'consultant/SAVE_CONSULTANT_BASIC_DATA'
export const SAVE_CONSULTANT_COMPANY_DATA = 'consultant/SAVE_CONSULTANT_COMPANY_DATA'
export const UPDATE_CONSULTANT_CONTRACT_SIGNATURE_DATE = 'consultant/UPDATE_CONSULTANT_CONTRACT_SIGNATURE_DATE'
export const UPDATE_CONSULTANT_CONTRACT_TERMINATION_SIGNATURE_DATE = 'consultant/UPDATE_CONSULTANT_CONTRACT_TERMINATION_SIGNATURE_DATE'
export const TRANSFER_CONSULTANTS = 'consultant/TRANSFER_CONSULTANTS'
export const GET_TRANSFERRED_CONSULTANTS = 'consultant/GET_TRANSFERRED_CONSULTANTS'
export const GET_TRANSFERABLE_CONSULTANTS = 'consultant/GET_TRANSFERABLE_CONSULTANTS'
export const OPEN_CHANGE_CONSULTANT_ROLE_SIDEBAR = 'consultant/OPEN_CHANGE_CONSULTANT_ROLE_SIDEBAR'
export const CHANGE_CONSULTANT_ROLE = 'consultant/CHANGE_CONSULTANT_ROLE'
export const GET_ROLE_CHANGE_IS_VALID = 'consultant/GET_ROLE_CHANGE_IS_VALID'
export const GET_AFFILIATION_TYPES = 'consultant/GET_AFFILIATION_TYPES'
export const DOWNLOAD_ON_CONSULTANTS = 'consultant/DOWNLOAD_ON_CONSULTANTS'
