import Vue from "vue";
import Vuex from "vuex";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import shared from "../modules/shared/store";
import account from "../modules/account/store";
import contract from "../modules/contract/store";
import product from "../modules/product/store";
import financial_solution from "../modules/financial_solution/store";
import partner_company from "../modules/partner_company/store";
import kpi from "../modules/kpi/store";
import consultant from "../modules/consultant/store";
import notification from "../modules/notification/store";
import report from "../modules/report/store";
import lead from "../modules/lead/store";
import franchises from "../modules/franchises/store";
import profile from "../modules/profile/store";
import customer from "../modules/customer/store";
import appointment from "../modules/appointment/store";
import system from "../modules/system/store";
import commission from "../modules/commission/store";
import withdrawal from "../modules/withdrawal/store";
import financial_adjustment from "../modules/financial_adjustment/store";
import financial_management from "../modules/financial_management/store";
import todo from "../modules/todo/store";
import dashboard from "../modules/dashboard/store";
import nps from "../modules/nps/store";
import support_ticket from "../modules/support_ticket/store";
import accounting_management from "../modules/accounting_management/store";
import access_management from "../modules/access_management/store";
import financial_planning from  "../modules/financial_planning/store";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    shared,
    account,
    contract,
    product,
    financial_solution,
    partner_company,
    kpi,
    consultant,
    notification,
    report,
    lead,
    profile,
    customer,
    appointment,
    system,
    commission,
    withdrawal,
    financial_adjustment,
    financial_management,
    franchises,
    dashboard,
    todo,
    nps,
    support_ticket,
    accounting_management,
    support_ticket,
    access_management,
    financial_planning
  },
});
