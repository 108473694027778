import _ from "lodash";
import * as types from "./types";

export default {
  [types.MUTATE_APPOINTMENTS_FROM_TODAY]: (state, payload) => {
    state.appointmentsFromToday = payload;
  },
  [types.MUTATE_BIRTHDAYS_THIS_MONTH]: (state, payload) => {
    state.birthdaysThisMonth = payload;
  },
  [types.MUTATE_CUSTOMERS_REMINDER]: (state, payload) => {
    state.customersReminder = payload;
  },
  [types.MUTATE_LEADS_REMINDER]: (state, payload) => {
    state.leadsReminder = payload;
  },
  [types.MUTATE_BANNERS]: (state, payload) => {
    state.banners = payload;
  },
  [types.MUTATE_APPOINTMENT_BADGES]: (state, payload) => {
    state.appointmentBadges = payload;
  },
  [types.MUTATE_SUBSCRIPTION_BADGES]: (state, payload) => {
    state.subscriptionBadges = payload;
  },
  [types.MUTATE_ROLE_BADGES]: (state, payload) => {
    state.roleBadges = payload;
  },
  [types.MUTATE_EP_BADGES]: (state, payload) => {
    state.epBadges = payload;
  },
  [types.MUTATE_TOTAL_EARNINGS_BADGES]: (state, payload) => {
    state.totalEarningsBadges = payload;
  },
  [types.MUTATE_CONSORTIUM_BADGES]: (state, payload) => {
    state.consortiumBadges = payload;
  },
  [types.MUTATE_LIFE_TOTALS_BADGES]: (state, payload) => {
    state.lifeTotalsBadges = payload;
  },
  [types.MUTATE_ROLE_BADGES_MODAL]: (state, payload) => {
    state.roleBadgesModal = payload;
  },
  [types.MUTATE_APPOINTMENT_BADGES_MODAL]: (state, payload) => {
    state.appointmentBadgesModal = payload;
  },
  [types.MUTATE_PRODUCTS_BADGES_MODAL]: (state, payload) => {
    state.productsBadgesModal = payload;
  },
  [types.MUTATE_TOTAL_EARNINGS_BADGES_MODAL]: (state, payload) => {
    state.totalEarningsBadgesModal = payload;
  },
};
