// Getters
export const APPOINTMENTS_FROM_TODAY = "dashboard/APPOINTMENTS_FROM_TODAY";
export const BIRTHDAYS_THIS_MONTH = "dashboard/BIRTHDAYS_THIS_MONTH";
export const CUSTOMERS_REMINDER = "dashboard/CUSTOMERS_REMINDER";
export const LEADS_REMINDER = "dashboard/LEADS_REMINDER";
export const BANNERS = "dashboard/BANNERS";
export const APPOINTMENT_BADGES = "dashboard/APPOINTMENT_BADGES";
export const SUBSCRIPTION_BADGES = "dashboard/SUBSCRIPTION_BADGES";
export const ROLE_BADGES = "dashboard/ROLE_BADGES";
export const EP_BADGES = "dashboard/EP_BADGES";
export const TOTAL_EARNINGS_BADGES = "dashboard/TOTAL_EARNINGS_BADGES";
export const CONSORTIUM_BADGES = "dashboard/CONSORTIUM_BADGES";
export const LIFE_TOTALS_BADGES = "dashboard/LIFE_TOTALS_BADGES";
export const ROLE_BADGES_MODAL = "dashboard/ROLE_BADGES_MODAL";
export const APPOINTMENT_BADGES_MODAL = "dashboard/APPOINTMENT_BADGES_MODAL";
export const PRODUCTS_BADGES_MODAL = "dashboard/PRODUCTS_BADGES_MODAL";
export const TOTAL_EARNINGS_BADGES_MODAL = "dashboard/TOTAL_EARNINGS_BADGES_MODAL";


// Mutations
export const MUTATE_APPOINTMENTS_FROM_TODAY = "dashboard/MUTATE_APPOINTMENTS_FROM_TODAY";
export const MUTATE_BIRTHDAYS_THIS_MONTH = "dashboard/MUTATE_BIRTHDAYS_THIS_MONTH";
export const MUTATE_CUSTOMERS_REMINDER = "dashboard/MUTATE_CUSTOMERS_REMINDER";
export const MUTATE_LEADS_REMINDER = "dashboard/MUTATE_LEADS_REMINDER";
export const MUTATE_BANNERS = "dashboard/MUTATE_BANNERS";
export const MUTATE_APPOINTMENT_BADGES = "dashboard/MUTATE_APPOINTMENT_BADGES";
export const MUTATE_SUBSCRIPTION_BADGES = "dashboard/MUTATE_SUBSCRIPTION_BADGES";
export const MUTATE_ROLE_BADGES = "dashboard/MUTATE_ROLE_BADGES";
export const MUTATE_EP_BADGES = "dashboard/MUTATE_EP_BADGES";
export const MUTATE_TOTAL_EARNINGS_BADGES = "dashboard/MUTATE_TOTAL_EARNINGS_BADGES";
export const MUTATE_CONSORTIUM_BADGES = "dashboard/MUTATE_CONSORTIUM_BADGES";
export const MUTATE_LIFE_TOTALS_BADGES = "dashboard/MUTATE_LIFE_TOTALS_BADGES";
export const MUTATE_ROLE_BADGES_MODAL = "dashboard/MUTATE_ROLE_BADGES_MODAL";
export const MUTATE_APPOINTMENT_BADGES_MODAL = "dashboard/MUTATE_APPOINTMENT_BADGES_MODAL";
export const MUTATE_PRODUCTS_BADGES_MODAL = "dashboard/MUTATE_PRODUCTS_BADGES_MODAL";
export const MUTATE_TOTAL_EARNINGS_BADGES_MODAL = "dashboard/MUTATE_TOTAL_EARNINGS_BADGES_MODAL";

// Actions
export const POST_APPOINTMENTS_FROM_TODAY =
  "dashboard/POST_APPOINTMENTS_FROM_TODAY";
export const GET_BIRTHDAYS_THIS_MONTH = "dashboard/GET_BIRTHDAYS_THIS_MONTH";
export const GET_CUSTOMERS_REMINDER = "dashboard/GET_CUSTOMERS_REMINDER";
export const GET_LEADS_REMINDER = "dashboard/GET_LEADS_REMINDER";
export const GET_BANNERS = "dashboard/GET_BANNERS";
export const GET_APPOINTMENT_BADGES = "dashboard/GET_APPOINTMENT_BADGES";
export const GET_SUBSCRIPTION_BADGES = "dashboard/GET_SUBSCRIPTION_BADGES";
export const GET_ROLE_BADGES = "dashboard/GET_ROLE_BADGES";
export const GET_EP_BADGES = "dashboard/GET_EP_BADGES";
export const GET_TOTAL_EARNINGS_BADGES = "dashboard/GET_TOTAL_EARNINGS_BADGES";
export const GET_CONSORTIUM_BADGES = "dashboard/GET_CONSORTIUM_BADGES";
export const GET_LIFE_TOTALS_BADGES = "dashboard/GET_LIFE_TOTALS_BADGES";
export const GET_ROLE_BADGES_MODAL = "dashboard/GET_ROLE_BADGES_MODAL";
export const GET_APPOINTMENT_BADGES_MODAL = "dashboard/GET_APPOINTMENT_BADGES_MODAL";
export const GET_PRODUCTS_BADGES_MODAL = "dashboard/GET_PRODUCTS_BADGES_MODAL";
export const GET_TOTAL_EARNINGS_BADGES_MODAL = "dashboard/GET_TOTAL_EARNINGS_BADGES_MODAL";