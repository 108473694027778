import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  appointmentsFromToday: [],
  birthdaysThisMonth: [],
  customersReminder: [],
  leadsReminder: [],
  banners: [],
  appointmentBadges: [],
  subscriptionBadges: [],
  roleBadges: [],
  epBadges: [],
  totalEarningsBadges: [],
  consortiumBadges: [],
  lifeTotalsBadges: [],
  roleBadgesModal: [],
  appointmentBadgesModal: [],
  productsBadgesModal: [],
  totalEarningsBadgesModal: []
};

export default {
  state,
  actions,
  getters,
  mutations,
};
