import * as types from "./types";

export default {
  [types.APPOINTMENTS_FROM_TODAY]: (state) => {
    return state.appointmentsFromToday;
  },
  [types.BIRTHDAYS_THIS_MONTH]: (state) => {
    return state.birthdaysThisMonth;
  },
  [types.CUSTOMERS_REMINDER]: (state) => {
    return state.customersReminder;
  },
  [types.LEADS_REMINDER]: (state) => {
    return state.leadsReminder;
  },
  [types.BANNERS]: (state) => {
    return state.banners;
  },
  [types.APPOINTMENT_BADGES]: (state) => {
    return state.appointmentBadges;
  },
  [types.SUBSCRIPTION_BADGES]: (state) => {
    return state.subscriptionBadges;
  },
  [types.ROLE_BADGES]: (state) => {
    return state.roleBadges;
  },
  [types.EP_BADGES]: (state) => {
    return state.epBadges;
  },
  [types.TOTAL_EARNINGS_BADGES]: (state) => {
    return state.totalEarningsBadges;
  },
  [types.CONSORTIUM_BADGES]: (state) => {
    return state.consortiumBadges;
  },
  [types.LIFE_TOTALS_BADGES]: (state) => {
    return state.lifeTotalsBadges;
  },
  [types.ROLE_BADGES_MODAL]: (state) => {
    return state.roleBadgesModal;
  },
  [types.APPOINTMENT_BADGES_MODAL]: (state) => {
    return state.appointmentBadgesModal;
  },
  [types.PRODUCTS_BADGES_MODAL]: (state) => {
    return state.productsBadgesModal;
  },
  [types.TOTAL_EARNINGS_BADGES_MODAL]: (state) => {
    return state.totalEarningsBadgesModal;
  }
};
