import { 
  READ_ACTION, 
  SUBSCRIPTIONS_STATICS, 
  KPI,
  PEFORMANCE_EVOLUTION
} from "@/constants/resources";
import { infoKPI } from "@/helpers/modalInfo";

export default [
  {
    path: "/kpi",
    name: "kpi-list",
    component: () => import("@/modules/kpi/views/KpiList.vue"),
    meta: {
      resource: KPI,
      action: READ_ACTION,
      pageTitle: "Faturômetro",
      info: infoKPI,
    },
  },
  {
    path: "/kpi/ranking-compromissos",
    name: "kpi-ranking-appoiments",
    component: () => import("@/modules/kpi/views/RankingAppoiments.vue"),
    meta: {
      resource: KPI,
      action: READ_ACTION,
      pageTitle: "Ranking de compromissos",
    },
  },
  {
    path: "/kpi/ranking-invoicing",
    name: "kpi-ranking-invoicing",
    component: () => import("@/modules/kpi/views/RankingInvoicing.vue"),
    meta: {
      resource: KPI,
      action: READ_ACTION,
      pageTitle: "Ranking de faturamento",
    },
  },
  {
    path: "/kpi/ranking-specialist",
    name: "kpi-ranking-specialist",
    component: () => import("@/modules/kpi/views/RankingSpecialist.vue"),
    meta: {
      resource: KPI,
      action: READ_ACTION,
      pageTitle: "Ranking de especialistas",
    },
  },
  {
    path: "/kpi/ranking-pilares",
    name: "kpi-ranking-pilares",
    component: () => import("@/modules/kpi/views/RankingPilares.vue"),
    meta: {
      resource: KPI,
      action: READ_ACTION,
      pageTitle: "Ranking campanha pilares",
    },
  },
  {
    path: "/kpi/estatisticas-assinaturas",
    name: "kpi-subscriptions-statistics",
    component: () => import("@/modules/kpi/views/SubscriptionStatistics.vue"),
    meta: {
      resource: SUBSCRIPTIONS_STATICS,
      action: READ_ACTION,
      pageTitle: "Estatísticas de assinatura",
    },
  },
  {
    path: "/kpi/evolucao-performance",
    name: "kpi-evolucao-performance",
    component: () => import("@/modules/kpi/views/PerformanceEvolution.vue"),
    meta: {
      resource: PEFORMANCE_EVOLUTION,
      action: READ_ACTION,
      pageTitle: "Evolução de performance",
    },
  },
];
