import {
  CONTRACT_RESOURCE,
  READ_ACTION
} from '@/constants/resources'
  

export default [
  {
    path: '/plano-financeiro/:id',
    name: 'financial-planning',
    component: () => import('@/modules/financial_planning/views/FinancialPlanning.vue'),
    meta: {
      resource: CONTRACT_RESOURCE,
      action: READ_ACTION
    },
  }
]
  