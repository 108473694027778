import * as types from './types'

export default {
  [types.RISK_PROTECTION_SIDEBAR]: state => {
    return state.riskProtectionSidebar
  },
  [types.RISK_PROTECTION_PRODUCTS]: state => {
    return state.riskProtectionProducts
  },
  [types.RISK_PROTECTION_COMPANIES]: state => {
    return state.riskProtectionCompanies
  },
  [types.RISK_PROTECTION_COVERAGES]: state => {
    return state.riskProtectionCoverages
  },
  [types.RISK_PROTECTION_NEEDS]: state => {
    return state.riskProtectionNeeds
  },
  [types.RISK_PROTECTIONS]: state => {
    return state.risksProtections
  },
  [types.ASSET_REBALANCING_METODOLOGIES]: state => {
    return state.assetRebalancingMetodologies
  },
  [types.WALLET_INVESTMENT_GOALS_SIDEBAR]: state => {
    return state.walletInvestmentGoalsSidebar
  },
  [types.INVESTMENT_PROFILE_SIDEBAR]: state => {
    return state.investmentProfileSidebar
  },
  [types.IMPLEMENTATIONS_SIDEBAR]: state => {
    return state.implementationsSidebar
  },
  [types.IMPLEMENTATIONS]: state => {
    return state.implementations
  },
  [types.PLANNING_STATUS]: state => {
    return state.planningStatus
  },
  [types.OBJECTIVES]: state => {
    return state.objectives
  },
}
