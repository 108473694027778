// Getters
export const CUSTOMER = "profile/CUSTOMER";
export const USER_ADDRESS_SIDEBAR = "profile/USER_ADDRESS_SIDEBAR";
export const USER_PERSONAL_DATA_SIDEBAR = "profile/USER_PERSONAL_DATA_SIDEBAR";
export const CUSTOMER_COMMENTS = "profile/CUSTOMER_COMMENTS";
export const CUSTOMER_TIMELINE_EVENTS = "profile/CUSTOMER_TIMELINE_EVENTS";
export const CUSTOMER_APPOINTMENTS = "profile/CUSTOMER_APPOINTMENTS";
export const CUSTOMER_CONTRACTS = "profile/CUSTOMER_CONTRACTS";
export const CUSTOMER_FILES = "profile/CUSTOMER_FILES";
export const UPLOAD_FILE_SIDEBAR = "profile/UPLOAD_FILE_SIDEBAR";
export const CUSTOMER_RECOMMENDATIONS = "profile/CUSTOMER_RECOMMENDATIONS";

// Mutations
export const MUTATE_CUSTOMER = "profile/MUTATE_CUSTOMER";
export const MUTATE_USER_ADDRESS_SIDEBAR =
  "profile/MUTATE_USER_ADDRESS_SIDEBAR";
export const MUTATE_USER_PERSONAL_DATA_SIDEBAR =
  "profile/MUTATE_USER_PERSONAL_DATA_SIDEBAR";
export const MUTATE_CUSTOMER_COMMENTS = "profile/MUTATE_CUSTOMER_COMMENTS";
export const MUTATE_CUSTOMER_TIMELINE_EVENTS =
  "profile/MUTATE_CUSTOMER_TIMELINE_EVENTS";
export const MUTATE_CUSTOMER_APPOINTMENTS =
  "profile/MUTATE_CUSTOMER_APPOINTMENTS";
export const MUTATE_CUSTOMER_CONTRACTS = "profile/MUTATE_CUSTOMER_CONTRACTS";
export const MUTATE_CUSTOMER_FILES = "profile/MUTATE_CUSTOMER_FILES";
export const MUTATE_UPLOAD_FILE_SIDEBAR = "profile/MUTATE_UPLOAD_FILE_SIDEBAR";
export const MUTATE_CUSTOMER_RECOMMENDATIONS =
  "profile/MUTATE_CUSTOMER_RECOMMENDATIONS";

// Actions
export const GET_CUSTOMER = "profile/GET_CUSTOMER";
export const OPEN_USER_ADDRESS_SIDEBAR = "profile/OPEN_USER_ADDRESS_SIDEBAR";
export const OPEN_USER_PERSONAL_DATA_SIDEBAR =
  "profile/OPEN_USER_PERSONAL_DATA_SIDEBAR";
export const GET_CUSTOMER_COMMENTS = "profile/GET_CUSTOMER_COMMENTS";
export const SAVE_NEW_COMMENT = "profile/SAVE_NEW_COMMENT";
export const UPDATE_COMMENT = "profile/UPDATE_COMMENT";
export const DELETE_COMMENT = "profile/DELETE_COMMENT";
export const GET_CUSTOMER_TIMELINE_EVENTS =
  "profile/GET_CUSTOMER_TIMELINE_EVENTS";
export const GET_CUSTOMER_APPOINTMENTS = "profile/GET_CUSTOMER_APPOINTMENTS";
export const UPDATE_CUSTOMER_PERSONAL_DATA =
  "profile/UPDATE_CUSTOMER_PERSONAL_DATA";
export const UPDATE_CUSTOMER_ADDRESS = "profile/UPDATE_CUSTOMER_ADDRESS";
export const UPDATE_CUSTOMER_PROFILE_PICTURE =
  "profile/UPDATE_CUSTOMER_PROFILE_PICTURE";
export const GET_CUSTOMER_CONTRACTS = "profile/GET_CUSTOMER_CONTRACTS";
export const GET_CUSTOMER_FILES = "profile/GET_CUSTOMER_FILES";
export const CONVERT_TO_INTERVIEW = "profile/CONVERT_TO_INTERVIEW";
export const GET_LEAD_PN = "profile/GET_LEAD_PN";
export const OPEN_UPLOAD_FILE_SIDEBAR = "profile/OPEN_UPLOAD_FILE_SIDEBAR";
export const STORE_UPLOAD_FILE = "profile/STORE_UPLOAD_FILE";
export const DELETE_CUSTOMER_FILE = "profile/DELETE_CUSTOMER_FILE";
export const DOWNLOAD_CUSTOMER_FILE = "profile/DOWNLOAD_CUSTOMER_FILE";
export const GET_CUSTOMER_RECOMMENDATIONS =
  "profile/GET_CUSTOMER_RECOMMENDATIONS";
export const GET_CUSTOMER_CASH_FLOW_TOKEN =
  "profile/GET_CUSTOMER_CASH_FLOW_TOKEN";
export const GET_CUSTOMER_FINANCIAL_DIAGNOSIS_TOKEN = "profile/GET_CUSTOMER_FINANCIAL_DIAGNOSIS_TOKEN"
