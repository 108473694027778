import axios from "axios";
import * as types from "./types";

export default {
  [types.POST_APPOINTMENTS_FROM_TODAY]: ({ commit }, payload) => {
    return axios
      .post(`/api/dashboard/get_appointments_from_today`, payload)
      .then((resp) => {
        commit(types.MUTATE_APPOINTMENTS_FROM_TODAY, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_BIRTHDAYS_THIS_MONTH]: ({ commit }, payload) => {
    return axios
      .get(`/api/dashboard/get_birthdays_from_this_month`, payload)
      .then((resp) => {
        commit(types.MUTATE_BIRTHDAYS_THIS_MONTH, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_CUSTOMERS_REMINDER]: ({ commit }, payload) => {
    return axios
      .get(`/api/dashboard/customers_reminder`, payload)
      .then((resp) => {
        commit(types.MUTATE_CUSTOMERS_REMINDER, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_LEADS_REMINDER]: ({ commit }, payload) => {
    return axios
      .get(`/api/dashboard/leads_reminder`, payload)
      .then((resp) => {
        commit(types.MUTATE_LEADS_REMINDER, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
    },
    [types.GET_BANNERS]: ({ commit }, payload) => {
      return axios
        .get(`/api/dashboard_banners/all`)
        .then((resp) => {
          commit(types.MUTATE_BANNERS, resp.data);
          return Promise.resolve(resp);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    [types.GET_APPOINTMENT_BADGES]: ({ commit }, payload) => {
      return axios
        .get(`/api/planner_gamification/appointment_badges`)
        .then((resp) => {
          commit(types.MUTATE_APPOINTMENT_BADGES, resp.data);
          return Promise.resolve(resp);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    [types.GET_SUBSCRIPTION_BADGES]: ({ commit }, payload) => {
      return axios
        .get(`/api/planner_gamification/subscription_badges`)
        .then((resp) => {
          commit(types.MUTATE_SUBSCRIPTION_BADGES, resp.data);
          return Promise.resolve(resp);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    [types.GET_ROLE_BADGES]: ({ commit }, payload) => {
      return axios
        .get(`/api/planner_gamification/role_badges`)
        .then((resp) => {
          commit(types.MUTATE_ROLE_BADGES, resp.data);
          return Promise.resolve(resp);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    [types.GET_EP_BADGES]: ({ commit }, payload) => {
      return axios
        .get(`/api/planner_gamification/ep_badges`)
        .then((resp) => {
          commit(types.MUTATE_EP_BADGES, resp.data);
          return Promise.resolve(resp);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    [types.GET_TOTAL_EARNINGS_BADGES]: ({ commit }, payload) => {
      return axios
        .get(`/api/planner_gamification/total_earnings`)
        .then((resp) => {
          commit(types.MUTATE_TOTAL_EARNINGS_BADGES, resp.data);
          return Promise.resolve(resp);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    [types.GET_CONSORTIUM_BADGES]: ({ commit }, payload) => {
      return axios
        .get(`/api/planner_gamification/consortium`)
        .then((resp) => {
          commit(types.MUTATE_CONSORTIUM_BADGES, resp.data);
          return Promise.resolve(resp);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    [types.GET_LIFE_TOTALS_BADGES]: ({ commit }, payload) => {
      return axios
        .get(`/api/planner_gamification/lifetotals`)
        .then((resp) => {
          commit(types.MUTATE_LIFE_TOTALS_BADGES, resp.data);
          return Promise.resolve(resp);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    [types.GET_ROLE_BADGES_MODAL]: ({ commit }, payload) => {
      return axios
        .get(`/api/planner_gamification/modal_role`)
        .then((resp) => {
          commit(types.MUTATE_ROLE_BADGES_MODAL, resp.data);
          return Promise.resolve(resp);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    [types.GET_APPOINTMENT_BADGES_MODAL]: ({ commit }, payload) => {
      return axios
        .get(`/api/planner_gamification/modal_appointment`)
        .then((resp) => {
          commit(types.MUTATE_APPOINTMENT_BADGES_MODAL, resp.data);
          return Promise.resolve(resp);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    [types.GET_PRODUCTS_BADGES_MODAL]: ({ commit }, payload) => {
      return axios
        .get(`/api/planner_gamification/modal_products`)
        .then((resp) => {
          commit(types.MUTATE_PRODUCTS_BADGES_MODAL, resp.data);
          return Promise.resolve(resp);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    [types.GET_TOTAL_EARNINGS_BADGES_MODAL]: ({ commit }, payload) => {
      return axios
        .get(`/api/planner_gamification/modal_total_earnings`)
        .then((resp) => {
          commit(types.MUTATE_TOTAL_EARNINGS_BADGES_MODAL, resp.data);
          return Promise.resolve(resp);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
};
