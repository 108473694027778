import {
  READ_ACTION,
  CREATE_ACTION,
  CONSULTANT_RESOURCE,
  OPPORTUNITY_MAP,
  PERFORMANCE_EVOLUTION,
  MANAGE_ACTION,
} from '@/constants/resources'

export default [
  {
    path: '/pns',
    name: 'consultants-list',
    component: () => import('@/modules/consultant/views/ConsultantsList.vue'),
    meta: {
      resource: CONSULTANT_RESOURCE,
      action: READ_ACTION,
      pageTitle: 'Minha equipe'
    },
  },
  {
    path: '/pn/:id?',
    name: 'consultant-save',
    component: () => import('@/modules/consultant/views/ConsultantSave.vue'),
    meta: {
      resource: CONSULTANT_RESOURCE,
      action: CREATE_ACTION,
      pageTitle: 'Novo PN'
    }
  },
  {
    path: '/pn/:id/informacoes_empresa',
    name: 'consultant-company-info-save',
    component: () => import('@/modules/consultant/views/ConsultantCompanyInfoSave.vue'),
    meta: {
      resource: CONSULTANT_RESOURCE,
      action: CREATE_ACTION,
      pageTitle: 'Dados da minha empresa'
    }
  },
  {
    path: '/pn/:id/detalhes',
    component: () => import('@/modules/consultant/views/ConsultantDetails.vue'),
    meta: {
      resource: CONSULTANT_RESOURCE,
      action: READ_ACTION
    },
    children: [
      {
        path: 'informacoes_gerais',
        name: 'consultant-details-summary',
        component: () => import('@/modules/consultant/views/ConsultantDetailsSummary.vue'),
        meta: {
          contentRenderer: 'sidebar-left',
          contentClass: 'todo-application',
          navActiveLink: 'consultants-list'
        },
      },
      {
        path: 'empresa',
        name: 'consultant-company-summary',
        component: () => import('@/modules/consultant/views/ConsultantCompanySummary.vue'),
        meta: {
          contentRenderer: 'sidebar-left',
          contentClass: 'todo-application',
          navActiveLink: 'consultants-list'
        },
      },
      {
        path: 'rede',
        name: 'consultant-network-summary',
        component: () => import('@/modules/consultant/views/ConsultantNetworkSummary.vue'),
        meta: {
          contentRenderer: 'sidebar-left',
          contentClass: 'todo-application',
          navActiveLink: 'consultants-list'
        },
      }
    ]
  },
  {
    path: '/transferir-pn',
    name: 'consultant-transfer',
    component: () => import('@/modules/consultant/views/ConsultantTransferList.vue'),
    meta: {
      resource: CONSULTANT_RESOURCE,
      action: READ_ACTION,
      pageTitle: 'Transferir PN'
    }
  },
  {
    path: '/mapa-de-oportunidades',
    name: 'opportunity-map-data-studio',
    component: () => import('@/modules/consultant/views/OpportunityMapDataStudio.vue'),
    meta: {
      resource: OPPORTUNITY_MAP,
      action: READ_ACTION,
      pageTitle: 'Mapa de Oportunidades'
    }
  },
]
