import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  riskProtectionSidebar: {
    id: undefined,
    saveAction: undefined,
    visible: false
  },
  riskProtectionProducts: [],
  riskProtectionCompanies: [],
  riskProtectionCoverages: [],
  riskProtectionNeeds: [],
  risksProtections: [],
  objectives: [],
  assetRebalancingMetodologies: [],
  walletInvestmentGoalsSidebar: {
    saveAction: undefined,
    visible: false
  },
  investmentProfileSidebar: {
    saveAction: undefined,
    visible: false
  },
  implementationsSidebar: {
    id: undefined,
    saveAction: undefined,
    visible: false
  },
  implementations: [],
  planningStatus: undefined
}

export default {
  state,
  actions,
  getters,
  mutations
}
